<template>
  <v-container id="map-container" fluid tag="section">
    <v-row justify="center">
      <v-col cols="12" md="12">
        <material-card color="primary" full-header class="card-group">
          <template #heading>
            <div class="pa-5 white--text">
              <div class="text-h4 font-weight-bold">
                <v-icon large class="mr-3">
                  mdi-map
                </v-icon>
                Echarts Basic Map
              </div>
            </div>
          </template>
          <v-col cols="12" class="pa-5">
            <div id="mapBasic" ref="mapBasic" class="map-basic" />
          </v-col>
        </material-card>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12" md="12">
        <material-card color="primary" full-header class="card-group">
          <template #heading>
            <div class="pa-5 white--text">
              <div class="text-h4 font-weight-bold">
                <v-icon large class="mr-3">
                  mdi-map
                </v-icon>
                Echarts Map Click Event
              </div>
            </div>
          </template>
          <v-col cols="12" class="pa-5 d-flex flex-column">
            <div id="mapTry" ref="mapTry" class="map-try" />
            <!-- 弹出框 -->
            <div id="box">
              <v-col class="d-flex align-center justify-space-between pa-0">
                <span id="box-title" />
                <span>
                  <v-btn float-left icon color="red lighten-2" @click="close">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </span>
              </v-col>
            </div>
            <div ref="chartPie" class="chart-pop-TR" />
            <div ref="chartBar" class="chart-pop-BR" />
          </v-col>
        </material-card>
      </v-col>
    </v-row>
    <!-- 由於 Indoor Map 寫在 index.html，每個 Component 都要建一個 id="myMap" 的元素，否則 console 會有錯誤訊息，更嚴謹的處理方案待研究。 -->
    <div id="myMap" />
  </v-container>
</template>

<script>
import 'echarts/map/js/china'
import bejingJson from '@/json/bejing.json'
import USAjson from '@/json/USA.json'

export default {
  name: 'MapEcharts',
  data () {
    return {
      options: {}
    }
  },
  mounted () {
    this.mapFnBasic()
    this.mapFnTry()
    this.chartsPie()
    this.chartsBar()
  },
  methods: {
    // 基礎地圖
    mapFnBasic () {
      // 基于准备好的dom，初始化echarts实例
      // var mapChart = this.$echarts.init(this.$refs.mapChart);
      var mapChartBasic = this.$echarts.init(
        document.getElementById('mapBasic')
      )
      this.$echarts.registerMap('USA', USAjson, {
        Alaska: {
          // 把阿拉斯加移到美国主大陆左下方
          left: -131,
          top: 25,
          width: 15
        },
        Hawaii: {
          left: -110, // 夏威夷
          top: 28,
          width: 5
        },
        'Puerto Rico': {
          // 波多黎各
          left: -76,
          top: 26,
          width: 2
        }
      })
      mapChartBasic.setOption({
        backgroundColor: '', // 背景颜色
        title: {
          text: '美國人口估計（2012 年）',
          subtext: 'Data from www.census.gov',
          sublink: 'http://www.census.gov/popest/data/datasets.html',
          color: '#fff',
          fontSize: '14px',
          // sublink: 'http://www.pm25.in',
          x: 'left'
        },
        // 是视觉映射组件，用于进行『视觉编码』，也就是将数据映射到视觉元素（视觉通道）。
        visualMap: {
          left: 'right',
          min: 500000,
          max: 38000000,
          inRange: {
            color: [
              '#313695',
              '#4575b4',
              '#74add1',
              '#abd9e9',
              '#e0f3f8',
              '#ffffbf',
              '#fee090',
              '#fdae61',
              '#f46d43',
              '#d73027',
              '#a50026'
            ]
          },
          text: ['High', 'Low'], // 文本，默认为数值文本
          calculable: true
        },
        // 提示框，鼠标移入
        tooltip: {
          trigger: 'item',
          showDelay: 0,
          transitionDuration: 0.2,
          formatter: function (params) {
            var value = (params.value + '').split('.')
            value = value[0].replace(/(\d{1,3})(?=(?:\d{3})+(?!\d))/g, '$1,')
            return params.seriesName + '<br/>' + params.name + ': ' + value
          }
        },
        toolbox: {
          show: true,
          // orient: 'vertical',
          left: 'right',
          top: 'top',
          feature: {
            dataView: { readOnly: false },
            restore: {},
            saveAsImage: {}
          }
        },
        // 配置地图的数据，并且显示
        series: [
          {
            name: 'USA PopEstimates',
            type: 'map',
            roam: true,
            map: 'USA',
            emphasis: {
              label: {
                show: true
              }
            },
            // 文本位置修正
            textFixed: {
              Alaska: [20, -20]
            },
            data: [
              { name: 'Alabama', value: 4822023 },
              { name: 'Alaska', value: 731449 },
              { name: 'Arizona', value: 6553255 },
              { name: 'Arkansas', value: 2949131 },
              { name: 'California', value: 38041430 },
              { name: 'Colorado', value: 5187582 },
              { name: 'Connecticut', value: 3590347 },
              { name: 'Delaware', value: 917092 },
              { name: 'District of Columbia', value: 632323 },
              { name: 'Florida', value: 19317568 },
              { name: 'Georgia', value: 9919945 },
              { name: 'Hawaii', value: 1392313 },
              { name: 'Idaho', value: 1595728 },
              { name: 'Illinois', value: 12875255 },
              { name: 'Indiana', value: 6537334 },
              { name: 'Iowa', value: 3074186 },
              { name: 'Kansas', value: 2885905 },
              { name: 'Kentucky', value: 4380415 },
              { name: 'Louisiana', value: 4601893 },
              { name: 'Maine', value: 1329192 },
              { name: 'Maryland', value: 5884563 },
              { name: 'Massachusetts', value: 6646144 },
              { name: 'Michigan', value: 9883360 },
              { name: 'Minnesota', value: 5379139 },
              { name: 'Mississippi', value: 2984926 },
              { name: 'Missouri', value: 6021988 },
              { name: 'Montana', value: 1005141 },
              { name: 'Nebraska', value: 1855525 },
              { name: 'Nevada', value: 2758931 },
              { name: 'New Hampshire', value: 1320718 },
              { name: 'New Jersey', value: 8864590 },
              { name: 'New Mexico', value: 2085538 },
              { name: 'New York', value: 19570261 },
              { name: 'North Carolina', value: 9752073 },
              { name: 'North Dakota', value: 699628 },
              { name: 'Ohio', value: 11544225 },
              { name: 'Oklahoma', value: 3814820 },
              { name: 'Oregon', value: 3899353 },
              { name: 'Pennsylvania', value: 12763536 },
              { name: 'Rhode Island', value: 1050292 },
              { name: 'South Carolina', value: 4723723 },
              { name: 'South Dakota', value: 833354 },
              { name: 'Tennessee', value: 6456243 },
              { name: 'Texas', value: 26059203 },
              { name: 'Utah', value: 2855287 },
              { name: 'Vermont', value: 626011 },
              { name: 'Virginia', value: 8185867 },
              { name: 'Washington', value: 6897012 },
              { name: 'West Virginia', value: 1855413 },
              { name: 'Wisconsin', value: 5726398 },
              { name: 'Wyoming', value: 576412 },
              { name: 'Puerto Rico', value: 3667084 }
            ]
          }
          // {
          //   type: 'scatter',
          //   showEffectOn: 'render', // 配置什么时候显示特效
          //   coordinateSystem: 'map', // 该系列使用的坐标系
          //   symbolSize: 20, // 标记的大小
          //   data: [
          //     { name: '宜昌', value: [111.3, 30.7, 130] },
          //   ],
          //   zlevel: 99999,
          // },
        ]
      })
      window.addEventListener('resize', () => {
        // 自动渲染echarts
        mapChartBasic.resize()
      })
    },
    mapFnTry () {
      var mapChartTry = this.$echarts.init(this.$refs.mapTry)
      // 載入 bejing JSON 檔案
      this.$echarts.registerMap('bejing', bejingJson)
      mapChartTry.setOption({
        animation: false,
        // geo方式地理坐标系组件。
        title: {
          text: '北京市行政區域圖',
          subtext: '點擊區域可觸發彈出視窗',
          color: '#fff',
          fontSize: '14px',
          // sublink: 'http://www.pm25.in',
          x: 'left'
        },
        tooltip: {
          show: true, // 鼠标移入是否触发数据
          trigger: 'item', // 出发方式
          formatter: '{b}-销售数量：{c}'
        },
        geo: {
          type: 'map',
          map: 'bejing',
          zoom: 1.2,
          label: {
            show: true,
            color: '#fff',
            fontSize: 16
            // 高亮状态下的多边形和标签样式。
          },
          // 选中状态下的多边形和标签样式
          emphasis: {
            itemStyle: {
              color: 'rgba(51, 69, 89, .5)', // 地图背景色
              borderColor: '#516a89', // 省市边界线00fcff 516a89
              borderWidth: 1
            },
            label: {
              color: '#000'
            }
          },
          // aspectScale:0.75, //长宽比
          roam: true, // 是否允许缩放
          itemStyle: {
            normal: {
              color: 'rgba(51, 69, 89, .5)', // 地图背景色
              borderColor: '#516a89', // 省市边界线00fcff 516a89
              borderWidth: 1
            },
            emphasis: {
              // 高亮状态下的样试
              label: {
                show: true
              }
            }
          }
        }
      })
      window.addEventListener('resize', () => {
        // 自动渲染echarts
        mapChartTry.resize()
      })

      mapChartTry.on('click', function (params) {
        // alert('QQQ')
        console.log(params) // 此处写点击事件内容
        // document.body.style.backgroundColor = 'green'
        const popBox = document.getElementById('box')
        popBox.classList.add('box-show')
        // popBox.style.left = params.clientX + 'px'
        // popBox.style.top = params.clientY + 'px'
        var provinceName = params.name
        document.getElementById('box-title').innerHTML = provinceName
      }) // 点击事件，此事件还可以用到柱状图等其他地图
    },
    chartsPie () {
      const myChartPie = this.$echarts.init(this.$refs.chartPie)
      myChartPie.setOption({
        title: {
          text: '目标文件类型图',
          left: '160'
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          left: 'left'
        },
        series: [
          {
            name: '访问来源',
            type: 'pie',
            radius: '50%',
            center: ['60%', '60%'],
            data: [
              {
                value: 1048,
                name: '军事目标',
                itemStyle: {
                  color: '#ee6666'
                }
              },
              {
                value: 735,
                name: '工业目标',
                itemStyle: {
                  color: '#91cc75'
                }
              },
              {
                value: 580,
                name: '交通目标',
                itemStyle: {
                  color: '#fac858'
                }
              },
              {
                value: 484,
                name: '城市目标',
                itemStyle: {
                  color: '#2455a4'
                }
              },
              {
                value: 300,
                name: '其它',
                itemStyle: {
                  color: '#73c0de'
                }
              }
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      })
      window.addEventListener('resize', () => {
        // 自动渲染echarts
        myChartPie.resize()
      })
    },
    chartsBar () {
      const myChartBar = this.$echarts.init(this.$refs.chartBar)
      myChartBar.setOption({
        title: {
          // text: '某地区蒸发量和降水量',
          // subtext: '纯属虚构',
          // top: 0,
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['蒸发量', '降水量'],
          top: 8,
          left: 10
        },
        toolbox: {
          show: true,
          feature: {
            dataView: { show: true, readOnly: false },
            magicType: { show: true, type: ['line', 'bar'] },
            restore: { show: true },
            saveAsImage: { show: true }
          }
        },
        grid: {
          height: 150
        },
        calculable: true,
        xAxis: [
          {
            type: 'category',
            data: [
              '1月',
              '2月',
              '3月',
              '4月',
              '5月',
              '6月',
              '7月',
              '8月',
              '9月',
              '10月',
              '11月',
              '12月'
            ]
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: [
          {
            name: '蒸发量',
            type: 'bar',
            data: [
              2.0,
              4.9,
              7.0,
              23.2,
              25.6,
              76.7,
              135.6,
              162.2,
              32.6,
              20.0,
              6.4,
              3.3
            ],
            markPoint: {
              data: [
                { type: 'max', name: '最大值' },
                { type: 'min', name: '最小值' }
              ]
            },
            markLine: {
              data: [{ type: 'average', name: '平均值' }]
            }
          },
          {
            name: '降水量',
            type: 'bar',
            data: [
              2.6,
              5.9,
              9.0,
              26.4,
              28.7,
              70.7,
              175.6,
              182.2,
              48.7,
              18.8,
              6.0,
              2.3
            ],
            markPoint: {
              data: [
                { name: '年最高', value: 182.2, xAxis: 7, yAxis: 183 },
                { name: '年最低', value: 2.3, xAxis: 11, yAxis: 3 }
              ]
            },
            markLine: {
              data: [{ type: 'average', name: '平均值' }]
            }
          }
        ]
      })
      window.addEventListener('resize', () => {
        // 自动渲染echarts
        myChartBar.resize()
      })
    },
    async close () {
      const popBox = document.getElementById('box')
      popBox.classList.remove('box-show')
    }
  }
}
</script>

<style scoped lang="scss">
#myMap {
  display: none;
}
.map-try {
  width: 100%;
  height: 700px;
}
.map-basic {
  width: 100%;
  height: 700px;
}
.map-pop {
  width: 100%;
  height: 700px;
}
.chart-pop-TR {
  background-color: white;
  position: absolute !important;
  padding: 10px;
  top: 100px;
  right: 20px;
  border-radius: 10px;
  width: 400px;
  height: 250px;
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%),
    0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 /12%);
}
.chart-pop-BR {
  background-color: white;
  position: absolute !important;
  padding: 10px;
  bottom: 20px;
  right: 20px;
  border-radius: 10px;
  width: 400px;
  height: 250px;
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%),
    0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 /12%);
}
@media (max-width: 414px) {
  .map-try {
    width: 100%;
    height: 300px;
  }
  .map-basic {
    width: 100%;
    height: 350px;
  }
  .chart-pop-TR {
    background-color: white;
    position: relative !important;
    top: 30px;
    left: 0px;
    margin: auto 0;
    padding: 10px;
    border-radius: 10px;
    width: 100%;
    height: 400px;
    box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%),
      0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 /12%);
  }
  .chart-pop-BR {
    margin-bottom: 60px;
    background-color: white;
    position: relative !important;
    top: 50px;
    left: 0px;
    padding: 10px;
    border-radius: 10px;
    width: 100%;
    height: 250px;
    box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%),
      0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 /12%);
  }
}
#box {
  // display: none;
  display: block;
  opacity: 0;
  padding: 0;
  background-color: white;
  position: absolute;
  padding: 10px;
  top: 200px;
  left: 20px;
  border-radius: 10px;
  width: 350px;
  height: 200px;
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%),
    0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 /12%);
  transition: all 0.3s;
}
#box-title {
  display: block;
  padding-left: 10px;
  font-weight: bold;
}
.box-show {
  opacity: 1 !important;
  top: 150px !important;
  transition: all 0.3s;
}
</style>
